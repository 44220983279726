<template>
	<div class="container">
		<div class="loginTop">
			<!-- <img :src="loginImg" class="loginBg">
            <img :src="logoImg" class="logoImg"> -->
			<div style="text-align: left;font-size: 26px;padding: 20px;">手机登录</div>
		</div>
		<van-cell-group>
			<van-field v-model="username" type="tel" label="手机号" label-width="4.2em" placeholder="请输入手机号"
				:rules="[{ required: true, message: '请输入手机号'}]" />
			<van-field v-model="captcha" label="验证码" label-width="4.2em" placeholder="请输入验证码"
				:rules="[{ required: true, message: '请输入验证码'}]">
				<template #button>
					<van-button type="primary" @click="showImgCaptcha()" size="small" :disabled="disable" id="sendBtn"
						color="#ffad1a"><a v-text="smsText"></a></van-button>
				</template>
			</van-field>
		</van-cell-group>
		<div v-show="imgCaptchaFlag" :style="{height: getWinHeight()+'px'}"
			style="position: absolute;top:0;left:0;width: 100%;z-index: 100;;">
			<div :style="{height: getWinHeight()+'px'}"
				style="position: absolute;width: 100%;top:0;background-color: #000;opacity: .5;"
				@click="imgCaptchaFlag=false">
			</div>
			<div
				style="position: absolute;top:50%;left:50%;transform: translate(-50%,-50%);width: 100%;background-color: #fff;padding:10px 20px;">
				<div class=""
					style="margin-top: 20px;display: flex;justify-content: center;flex-direction: column;align-items: center;">
					<img :src="captchaImgUrl" mode="widthFixed" width="200" height="66"
						@click="refreshCaptchaImgUrl()" />
					<div class="" style="padding:24px 14px 14px;justify-content: center;">
						<input v-model="imgCaptcha" style="border:1px solid #ededed;padding:6px 20px;width: 100px;" />
						<div style="display: inline-block;width: 100px;position: relative;top:-5px;">
							<van-button type="primary" @click="sendPhoneCode()" size="small"
								color="#ffad1a">确定</van-button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div>
			<van-button type="warning" color="#ffad1a" block @click="smsLogin">登 录</van-button>
		</div>
		<div style="display: flex;justify-content: space-between;    padding: 2px 12px;">
			<a href="javascript:;" class="forget-text" style="color:black;" @click="login">账号登录 ></a>
			<!-- <a href="javascript:;" class="forget-text" @click="forgetPwd">忘记密码?</a> -->
		</div>
	</div>
</template>
<script type="text/javascript">
	import request from '@/utils/request'
	import {
		Toast
	} from 'vant';
	export default {
		name: "SmsLogin",
		data() {
			return {
				loginImg: require("@/assets/images/loginBg.png"),
				logoImg: require("@/assets/images/logo.png"),
				username: "",
				captcha: "",
				smsText: "获取验证码",
				disable: false,
				timer: null,
				nextTime: 0,
				imgId: "",
				imgCaptcha: "",
				imgCaptchaFlag: false,
				captchaImgUrl: "",
			}
		},
		created() {
			this.refreshCaptchaImgUrl();
			this.imgId = new Date().getTime() + parseInt(10000 + Math.random() * 10000);
		},
		methods: {
			forgetPwd() {
				this.$router.push("/forgetPwd")
			},
			login() {
				this.$router.push("/login")
			},
			showImgCaptcha() {
				if (this.nextTime > 0) {
					return;
				}
				if (!this.username) {
					Toast.fail("请输入手机号!")
					return;
				}
				this.refreshCaptchaImgUrl();
				this.imgCaptchaFlag = true;
			},
			refreshCaptchaImgUrl() {
				let isPro=location.hostname.indexOf('51scf')>0||location.hostname.indexOf('fujingyun')>0;
				this.captchaImgUrl = "http://" + location.hostname +(isPro?'/api/':'')+ "/api/common/captcha?id=" + this.imgId + "&t=" +
					new Date().getTime();;
			},
			smsLogin() {
				if (!this.username) {
					Toast.fail("请输入手机号!")
					return;
				}
				if(!this.tool.isMobile(this.username)){
					Toast.fail("手机号不正确!")
					return;
				}
				if (!this.captcha) {
					Toast.fail("请输入验证码!")
					return;
				}
				request.post('/api/login/smsLogin', {
					phone: this.username,
					captcha: this.captcha
				}).then(response => {
					if (response.code == 0) {
						this.onLoginSuccess(response.data);
						//回调地址
						if(!response.data.wxAuth&&this.isWeiXinBrower()){
						
						  let wxUrl="https://open.weixin.qq.com/connect/oauth2/authorize?appid="+response.data.appId;
						
							wxUrl+= "&redirect_uri="+encodeURIComponent(location.origin+"/mine");
						
							wxUrl+="&response_type=code&scope=snsapi_userinfo#wechat_redirect";
							// alert(wxUrl)
							location.href=wxUrl; 
							return;
						}
					} else {
						Toast.fail(response.msg);
					}
				});
			},
			sendPhoneCode() {
				let username = this.username;
				if (!username) {
					Toast.fail('手机号码不能空');
					return
				}
				let numbers = /^1\d{10}$/;
				if (!numbers.test(username) || username.length == 0) {
					Toast.fail('');
					return
				}
				this.disable = true;
				request.post('/api/common/sendPhoneCode', {
					phone: username,
					imgId: this.imgId,
					captcha: this.imgCaptcha,
					type: "smslogin"
				}).then(response => {
					if (response.code == 0 || response.success) {
						this.imgCaptchaFlag = false;
						//计时开始
						this.nextTime = response.data;
						this.startInterval();
					} else {
						if (response.msg.indexOf("频繁") > -1) {
							this.imgCaptchaFlag = false;
						}
						this.nextTime = response.data;
						this.startInterval();
						Toast.fail(response.msg);
					}
				});
			},
			startInterval() {
				let that = this;
				this.timer = setInterval(function() {
					that.nextTime--;
					if (that.nextTime <= 0) {
						that.smsText = '获取验证码';
						clearInterval(that.timer);
						that.nextTime = 60;
						that.disable = false;
					} else {
						that.smsText = that.nextTime + '秒后重试';
						that.disable = true;
					}
				}, 1000);
			}
		}
	}
</script>
<style type="text/css" scoped="">
	.container {
		height: 100%;
		width: 100%;
		position: fixed;
		left: 0px;
		right: 0px;
		bottom: 0px;
		left: 0px;
		background: #fff;
		padding: 10% 5%;
		box-sizing: border-box;
	}

	.forget-text {
		font-size: 14px;
		float: right;
		padding: 5px 0px;
		color: red;
	}

	.loginTop {
		position: relative;
		margin-bottom: 10%;
	}

	.loginBg {
		width: 100%;
	}

	.logoImg {
		position: absolute;
		margin: 0 auto;
		top: 50%;
		left: 50%;
		width: 25%;
		transform: translate(-50%, -50%);
	}

	.van-button {
		width: 95%;
		margin: 15% 2% 5% 2%;
		border-radius: 30px;
		background: -webkit-linear-gradient(left, #ff6034, #ee0a24) !important;
		background: linear-gradient(to right, #ff6034, #ee0a24) !important;
		border: 0px;
		border: 0px;
	}

	#sendBtn {
		padding: 0px 10px;
		margin: 0px;
		width: auto;
	}
</style>